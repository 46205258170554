import React from "react";
import { Link } from "gatsby";
import { CardColumns, Card } from "react-bootstrap";
import Title from "./Title";
import { FaSearch } from "react-icons/fa";
import { StaticImage } from "gatsby-plugin-image";

const Specialization = () => {
  return (
    <section className="section bg-grey">
      <Title title="Inspection Specialization" />
      <div>
        <CardColumns className="specialization">
          <Link href="/residential-home-inspections">
            <Card className="specialization-card">
              <StaticImage
                src="../images/residential.jpg"
                alt="Residential Inspections"
              />
              <div class="bottom-left">Residential Inspections</div>
              <div class="bottom-right">
                <FaSearch />
              </div>
            </Card>
          </Link>
          <Link href="/commercial-building-inspections">
            <Card className="specialization-card">
              <StaticImage
                src="../images/commercial.jpg"
                alt="Commercial Inspections"
              />
              <div class="bottom-left">Commercial Inspections</div>
              <div class="bottom-right">
                <FaSearch />
              </div>
            </Card>
          </Link>
          <Link href="/residential-home-inspections#Home Maintenance Inspection">
            <Card className="specialization-card">
              <StaticImage
                src="../images/home-maintenance.jpg"
                alt="Booklets"
              />
              <div class="bottom-left">Home Maintenance Inspections</div>
              <div class="bottom-right">
                <FaSearch />
              </div>
            </Card>
          </Link>
          <Link href="/construction-warranty-insurance-inspections">
            <Card className="specialization-card">
              <StaticImage
                src="../images/new-construction.jpg"
                alt="New Construction Inspections"
              />
              <div class="bottom-left">New Construction Inspections</div>
              <div class="bottom-right">
                <FaSearch />
              </div>
            </Card>
          </Link>
          <Link href="/specialized-residential-inspections">
            <Card className="specialization-card">
              <StaticImage
                src="../images/termite.jpg"
                alt="Termite Inspections"
              />
              <div class="bottom-left">Termite Inspections</div>
              <div class="bottom-right">
                <FaSearch />
              </div>
            </Card>
          </Link>
          <Link href="/specialized-residential-inspections#Mold">
            <Card className="specialization-card">
              <StaticImage src="../images/mold.jpg" alt="Mold Inspections" />
              <div class="bottom-left">Mold Inspections</div>
              <div class="bottom-right">
                <FaSearch />
              </div>
            </Card>
          </Link>
        </CardColumns>
      </div>
    </section>
  );
};

export default Specialization;
