import { Link } from "gatsby";
import React from "react";
import { Carousel, Container } from "react-bootstrap";

const Homehero = () => {
  return (
    <Carousel>
      <Carousel.Item interval={4000}>
        <div className="overlay-hero banner banner-page banner-home banner-index mb-30">
          <Container>
            <div className="banner-head-home pull-left">
              <h3 className="d-no-xs">Welcome To</h3>
              <h2>Young Home Inspections</h2>
              <p>Over 30 years of dedicated service</p>
              <Link href="/about" className="btn btn-secondary">
                Learn More
              </Link>
            </div>
          </Container>
        </div>
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <div className="overlay-hero banner banner-home banner-page banner-index2 mb-30">
          <Container>
            <div className="banner-head-home pull-right">
              <h2>
                <strong>Your Inspection</strong>
              </h2>
              <p>Done the Right Way!</p>
              <Link href="/about" className="btn btn-secondary">
                Learn More
              </Link>
            </div>
          </Container>
        </div>
      </Carousel.Item>
    </Carousel>
  );
};

export default Homehero;
