import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const Mission = () => {
  return (
    <div className="overlay-hero missionbanner mt-5 mb-30">
      <Container className="mission">
        <Row>
          <Col>
            <div>
              <h3 className="missionheader">
                The Young Home Inspections' Mission
              </h3>
            </div>
            <div className="mb-50">
              <p>
                "We strive to satisfy our clients with stellar customer service
                from the point of first contact, through the inspection, and
                into the future.
              </p>
              <p>
                With integrity and pride, we set the standard of excellence in
                Central Florida home inspections."
              </p>
            </div>
            <div>
              <StaticImage src="../images/young-sig.png" />
              <p>Owner | Young Home Inspections</p>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Mission;
