import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import CTA1 from "../components/CTA1";
import Homehero from "../components/hero/Homehero";
import Mission from "../components/Mission";
import Services from "../components/Services";
import Specialization from "../components/Specialization";
import Locations from "../components/Locations";
import Testimonials from "../components/Testimonials";

const IndexPage = () => {
  return (
    <Layout>
      <title>Home Page</title>
      <Homehero />
      <Container>
        <Row className="cert-banner mb-100">
          <Col>
            <a href="https://www.nachi.org/">
              <StaticImage
                src="../images/internachi-certified.png"
                alt="Internachi certified"
                target="_blank"
              />
            </a>
          </Col>
          <Col>
            <a href="https://www.angi.com/companylist/us/fl/new-port-richey/young-home-inspections-reviews-153513.htm">
              <StaticImage
                src="../images/Angi_SSA_2020_HighRes.jpeg"
                alt="Angies List"
                target="_blank"
              />
            </a>
          </Col>
          <Col>
            <a href="https://fabi.org/">
              <StaticImage
                src="../images/fabi-rpi.png"
                alt="FABI"
                target="_blank"
              />
            </a>
          </Col>
          <Col>
            <a href="https://www.nachi.org/certified-inspectors/brian-perry-cbc1251244-hi5282-43730">
              <StaticImage
                src="../images/internachi-cpi.png"
                alt="Internachi CPI"
                target="_blank"
              />
            </a>
          </Col>
        </Row>
      </Container>
      <Row className="marg-0">
        <Col md={6} className="leftcolumn">
          <p>
            <strong>Offering the Best in Inspections</strong>
          </p>
          <p>
            Young Home Inspections has been conducting residential and
            commercial property inspections since 1989. Serving 10's of
            thousands of customers and still going strong.
          </p>
          <Link href="/about" className="link">
            <p>Take a Tour</p>
          </Link>
        </Col>
        <Col md={6} className="rightcolumn">
          <p>
            <strong>The Importance of Inspections</strong>
          </p>
          <p>
            Inspections are a vital part of any real estate transaction. Whether
            you are a buyer or seller it is important to know what the current
            condition of the property is.
          </p>
          <Link href="/residential-home-inspections" className="linksecondary">
            <p>Take a Tour</p>
          </Link>
        </Col>
      </Row>
      <CTA1 />
      <Container className="mt-30 text-center">
        <div>
          <h2>Home Inspection Services offered by Young Home Inspections</h2>
          <h3>
            In the New Port Richey, Tampa, St. Petersburg, Clearwater, Spring
            Hill, Lakewood Ranch, Riverview, Wesley Chapel, Plant City and Land
            O' Lakes, FL areas!
          </h3>
        </div>
      </Container>
      <Services />
      <Specialization />
      <Container className="mt-30 mb-30">
        <Row className="marg-0">
          <Col>
            <a href="/warranties/YHI_90Day.pdf" target="_blank" download>
              <StaticImage src="../images/90-day-warranty.png" />
            </a>
          </Col>
          <Col>
            <a href="../pages/warranties/YHI_MoldSafe.pdf" download>
              <StaticImage src="../images/mold-safe.png" />
            </a>
          </Col>
          <Col>
            <a href="../pages/warranties/YHI_Roof.pdf" download>
              <StaticImage src="../images/platinum-roof.png" />
            </a>
          </Col>
          <Col>
            <a href="../pages/warranties/YHI_ReCallChek.pdf" download>
              <StaticImage src="../images/recall-chek.png" />
            </a>
          </Col>
          <Col>
            <a href="../pages/warranties/YHI_SewerGuard.pdf" download>
              <StaticImage src="../images/sewer-gard.png" />
            </a>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col sm={6} className="fifty-split-1">
            <h3>About Us</h3>
            <p>
              With over 30 years of experience our list of specialty services
              have grown. We specialize in Residential pre-purchase & pre-sales
              inspections, commercial property inspections, new construction
              phase inspections, 11-month warranty inspections, and more!
            </p>
            <p>
              We conduct full comprehensive building inspections that include
              everything from Roof and Attic to Exteriors Walls, Soffit, Fascia,
              Gutters, Paint, any type of Siding, Sidewalks, Decks, Site
              drainage and grading, irrigation, Interior Walls, , floors,
              ceilings, doors, windows, appliances. HVAC, plumbing, electrical,
              pools/spas, birdcages, detached buildings like garages, mother in
              law suites, barns and stables, docks, seawalls, and boatlifts.
            </p>
            <Link to="/about" className="btn">
              Read More
            </Link>
          </Col>
          <Col sm={6} className="fifty-split-2">
            <iframe
              src="https://iplayerhd.com/player/video/EDBA8950-1CFE-4E64-9B7F-BA771B6FBF0D?cbartype=auto"
              className="video-wrapper"
              allowtransparency="true"
              scrolling="no"
              allowfullscreen="true"
              mozallowfullscreen=""
              webkitallowfullscreen=""
              oallowfullscreen=""
              msallowfullscreen=""
              frameborder="0"
            ></iframe>
          </Col>
        </Row>
      </Container>
      <Mission />
      <Testimonials />
      <Locations />
    </Layout>
  );
};

export default IndexPage;
