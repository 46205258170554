import React from "react";
import { Carousel } from "react-bootstrap";

function Testimonials() {
  return (
    <div>
      <Carousel variant="dark">
        <Carousel.Item>
          <Carousel.Caption>
            <p>
              The appointment was easy to schedule. Christine was great! Brian
              showed up early and was thorough in the inspection, very
              personable and answered all of our questions. He explained to my
              husband how some systems worked with suggestions on changes we
              might want to make. After the inspection, he sat down with us and
              covered all points of the inspection. He provided a wind
              mitigation report that we submitted to our insurance company
              entitling us to a nice discount on our homeowner’s insurance. We
              had the detailed inspection and wind mitigation reports that
              evening. Christine arranged for Knight Pest Control to do the
              termite inspection at the same time. That was included in the cost
              of the home inspection. We were pleased with the entire process of
              Young Home Inspections and are very confident purchasing this home
              because I feel we are aware of any issues with the home.
            </p>
            <h5>Linda A</h5>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Carousel.Caption>
            <p>
              I wanted to thank you & Brian for your services & the excellent
              professional inspection report your firm performed for us. I
              wanted to give you an update as you have been so sweet as well as
              professional..... due to the findings & potential expenses we
              would have to incur, we have terminated our contract. We truly
              thought the home required more 'cosmetic repairs' vs. major high
              end maintenance expenses. It would of been so nice to get to know
              you as 'neighbors'..... There are so many exciting things going on
              now in NPR, I know we would of enjoyed the area so much.....
            </p>
            <h5>Barb G</h5>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Carousel.Caption>
            <p>
              Thank You so much Christy & Brian for such fast and prompt and
              thorough great service. We got everything and waiting on the
              termite only. Great job
              <br />
              You guys are awesome!
            </p>
            <h5>Mike S</h5>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Carousel.Caption>
            <p>
              Brandon was my home inspector and he was wonderful, courteous,
              friendly, knowledgeable, approachable, the list goes on. He was
              thorough with his inspection and taking the time to explain his
              finding to me, and answer all my questions, and emailed me my
              results the next day. Pleasant and experience even which Christina
              in the office, she was very quick to reach out to me and schedule
              an appointment. The whole process took less then 4 days. Would use
              them again and highly recommend them.
            </p>
            <h5>Tabetha B</h5>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Carousel.Caption>
            <p>
              We were from out of state and had no idea with whom to trust. Our
              realtor said he could get one of "his" trusted people to do a home
              inspection for us. I just was not comfortable with his answers and
              timeliness of those answers. He told me they really did not even
              use Angie's list in that area. Well some of us do. Angie's list
              provided me with a fabulous home inspector. Christina was great to
              work with on setting it up. She went out of her way to coordinate
              with us and our realtor. Her husband, did our inspection. Due to
              the information provided on the inspection, we decided to pass on
              the house. The inspector explained everything to us showing us
              exactly what he was talking about in his report. We were able to
              make informed decisions.
            </p>
            <h5>Karen W</h5>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Carousel.Caption>
            <p>
              Just wanted to send a short note to thank you for your help and
              the professionalism of the company. My review on Angie's List was
              submitted tonight. You can be sure that we will recommend you and
              your company and will use your services if the need arises in the
              future.
            </p>
            <h5>David B</h5>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Carousel.Caption>
            <p>
              I had contracted Young Home Inspection two times for
              Clearwater/Largo townhouses (1st inspection found too many issues,
              including a nosy and potentially problematic neighbor, so the
              purchase was dropped). The office manager Christy was great with
              answering questions, scheduling and follow-up. Brian had conducted
              the inspection for both properties and was personable, on time,
              took his time and was thorough. He reviewed his findings and made
              suggestions on how best to remediate several issues that were
              found. Additionally, both termite companies Christy had obtained
              satisfactorily performed their inspections.
            </p>
            <h5>Robert T</h5>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Carousel.Caption>
            <p>
              They did a thorough job. The report is very detailed, and you can
              cursor over the photos to enlarge them. It seems they covered
              everything, more than the usual report – in my opinion. He
              explained everything to us, which all show in the report exactly.
              Overall, I liked him, thought he was professional and did a
              thorough job, and the report is a good format.
            </p>
            <h5>Joe B</h5>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Carousel.Caption>
            <p>
              The house was inspected prior to my purchasing the home. Brian did
              a very comprehensive inspection and left nothing to chance. It was
              great. He was professional and courteous. Chrissy (who makes the
              appointments and answers the tough questions) is top notch. I
              would not hesitate to use Young Home Inspections again.
            </p>
            <h5>Julie G</h5>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Carousel.Caption>
            <p>
              The scheduling over phone was friendly and they were able to get
              me an appointment very quickly. They arrived early, were fully
              equipped to do all the tasks. They were professional in dress and
              behavior. They were very informative, accommodating, and thorough.
              The final report was comprehensive and professionally done. It was
              easily downloaded and it was very detailed. They lived up to the
              reputation they have established on Angie's List and it is clear
              why they are so highly rated. I would absolutely recommend using
              them.
            </p>
            <h5>Thomas E</h5>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Carousel.Caption>
            <p>
              Young Home Inspections is not only punctual and professional, but
              accommodating and understanding. The inspector, Brandon, did the
              four-point inspection on our older home and went above and beyond
              by paying attention to every detail. He was extremely
              knowledgeable and answered all of questions. I highly recommend
              this company and I will use them in the future. I can't say enough
              about how accommodating they were! I had an odd situation with the
              home we were buying, and the appointment time had to be later in
              the evening. Christy was wonderful and was glad to help me out!
              Great job, very happy!
            </p>
            <h5>Jacqueline S</h5>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Carousel.Caption>
            <p>
              We live in Texas and just bought a condo in Florida. I looked on
              Angie's List to find a reputable Home Inspection company. I found
              Young Home Inspections. They not only had exceptional reviews but
              they even had a coupon I could use. I called and spoke to
              Christine. What a pleasure to deal with. She was so professional
              and friendly and assured me that they would take care of our
              needs. We were able to get our inspection done on the exact date
              and time. A friend of ours from Florida went to the inspection on
              our behalf. He said the inspector was great. Very professional.
              Arrived right on time and did a thorough inspection. I was very
              grateful we found them and they had excellent communication which
              made us worry-free for the entire process. I highly recommend them
              and will use them again in the future!! Thanks Christine and
              team!!
            </p>
            <h5>Laura H</h5>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default Testimonials;
